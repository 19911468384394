var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trip-plan-point"},[_c('div',{staticClass:"trip-plan-point__wrapper"},[(_vm.$viewport.desktop)?_c('Column',[_c('Row',{staticClass:"trip-plan-point__top",attrs:{"justify":"between"}},[_c('div',{staticClass:"trip-plan-point__image-holder"},[(!!_vm.params.images)?_c('img',{staticClass:"trip-plan-point__image",attrs:{"src":_vm.params.images[0],"alt":""}}):_vm._e()]),_c('Column',{staticClass:"grid-3",attrs:{"justify":"between"}},[_c('div',[_c('div',{staticClass:"trip-plan-point__number-container"},[_c('p',{staticClass:"trip-plan-point__number"},[_vm._v(_vm._s(_vm.params.index))]),(_vm.params.type.image.includes('.svg'))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.svgImg())}}):_c('img',{attrs:{"src":_vm.params.type.image,"width":"32px","height":"32px"}})]),_c('Divider',{staticClass:"trip-plan-point__divider"}),_c('router-link',{staticClass:"trip-plan-point__title",attrs:{"to":_vm.params.link}},[_vm._v(_vm._s(_vm.params.title)+" ")])],1),_c('Rating',{staticClass:"trip-plan-point__rating",attrs:{"params":{id: _vm.params.id,
                  type: 'places',
                  rating: _vm.params.rating,
                  currentUserRating: _vm.params.currentUserRating}}})],1)],1),(_vm.params.annotation)?_c('div',{staticClass:"trip-plan-point__annotation"},[_c('p',{staticClass:"trip-plan-point__annotation-text",domProps:{"innerHTML":_vm._s(_vm.params.annotation)}})]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('Row',{staticClass:"trip-plan-point__full-info",attrs:{"justify":"between"}},[_c('Column',{staticClass:"grid-3"},_vm._l((_vm.array1),function(item){return _c('div',{key:item.id,staticClass:"trip-plan-point__full-info-block"},[_c('Column',[_c('div',{staticClass:"trip-plan-point__full-info-title"},[_vm._v(_vm._s(item.key))]),_c('div',{staticClass:"trip-plan-point__full-info-value"},[_vm._v(_vm._s(item.value))])])],1)}),0),_c('Column',{staticClass:"grid-3"},_vm._l((_vm.array2),function(item){return _c('div',{key:item.id,staticClass:"trip-plan-point__full-info-block"},[_c('Column',[_c('div',{staticClass:"trip-plan-point__full-info-title"},[_vm._v(_vm._s(item.key))]),_c('div',{staticClass:"trip-plan-point__full-info-value"},[_vm._v(_vm._s(item.value))])])],1)}),0)],1):_vm._e()],1),(_vm.params.additions.length > 0)?_c('div',[_c('span',{staticClass:"trip-plan-point__toggle",on:{"click":_vm.toggle}},[_vm._v("Узнать больше ")])]):_vm._e()],1):_vm._e(),(_vm.$viewport.mobile)?_c('Column',[_c('Row',{attrs:{"justify":"between"}},[_c('span',{staticClass:"trip-plan-point__number"},[_vm._v(_vm._s(_vm.params.index))]),_c('router-link',{staticClass:"trip-plan-point__title",attrs:{"to":_vm.params.link}},[_vm._v(_vm._s(_vm.params.title)+" ")]),_c('Rating',{staticClass:"trip-plan-point__rating",attrs:{"params":{id: _vm.params.id,
                type: 'places',
                rating: _vm.params.rating,
                currentUserRating: _vm.params.currentUserRating}}})],1),_c('div',{staticClass:"trip-plan-point__image-holder"},[(!!_vm.params.images)?_c('img',{staticClass:"trip-plan-point__image",attrs:{"src":_vm.params.images[0],"alt":""}}):_vm._e()]),(_vm.params.annotation)?_c('div',{staticClass:"trip-plan-point__annotation"},[_c('p',{staticClass:"trip-plan-point__annotation-text"},[_vm._v(_vm._s(_vm.params.annotation))])]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"trip-plan-point__full-info"},_vm._l((_vm.params.additions),function(item){return _c('div',{key:item.id,staticClass:"trip-plan-point__full-info-block"},[_c('Column',[_c('div',{staticClass:"trip-plan-point__full-info-title"},[_vm._v(_vm._s(item.key))]),_c('div',{staticClass:"trip-plan-point__full-info-value"},[_vm._v(_vm._s(item.value))])])],1)}),0):_vm._e()]),(_vm.params.additions.length > 0)?_c('div',[_c('span',{staticClass:"trip-plan-point__toggle",on:{"click":_vm.toggle}},[_vm._v("Узнать больше ")])]):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }