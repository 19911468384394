<template>
  <div class="trip-plan-point">
    <div class="trip-plan-point__wrapper">
      <Column v-if="$viewport.desktop">
        <Row class="trip-plan-point__top"
             justify="between">
          <div class="trip-plan-point__image-holder">
            <img v-if="!!params.images"
                 class="trip-plan-point__image"
                 :src="params.images[0]"
                 alt/>
          </div>
          <Column class="grid-3"
                  justify="between">
            <div>
              <div class="trip-plan-point__number-container">
                <p class="trip-plan-point__number">{{params.index}}</p>
                <div
                  v-if="params.type.image.includes('.svg')"
                  v-html="svgImg()"/>
                <img v-else :src="params.type.image" width="32px" height="32px">
              </div>
              <Divider class="trip-plan-point__divider"/>
              <router-link :to="params.link"
                           class="trip-plan-point__title">{{params.title}}
              </router-link>
            </div>
            <Rating class="trip-plan-point__rating"
                    :params="{id: params.id,
                    type: 'places',
                    rating: params.rating,
                    currentUserRating: params.currentUserRating}"/>
          </Column>
        </Row>
        <div v-if="params.annotation"
             class="trip-plan-point__annotation">
          <p class="trip-plan-point__annotation-text" v-html="params.annotation"></p>
        </div>
        <transition name="fade">
          <Row class="trip-plan-point__full-info"
               justify="between"
               v-if="show">
            <Column class="grid-3">
              <div v-for="item in array1"
                   :key="item.id"
                   class="trip-plan-point__full-info-block">
                <Column>
                  <div class="trip-plan-point__full-info-title">{{item.key}}</div>
                  <div class="trip-plan-point__full-info-value">{{item.value}}</div>
                </Column>
              </div>
            </Column>
            <Column class="grid-3">
              <div v-for="item in array2"
                   :key="item.id"
                   class="trip-plan-point__full-info-block">
                <Column>
                  <div class="trip-plan-point__full-info-title">{{item.key}}</div>
                  <div class="trip-plan-point__full-info-value">{{item.value}}</div>
                </Column>
              </div>
            </Column>
          </Row>
        </transition>
        <div v-if="params.additions.length > 0">
          <span class="trip-plan-point__toggle"
                v-on:click="toggle">Узнать больше
          </span>
        </div>
      </Column>
      <Column v-if="$viewport.mobile">
        <Row justify="between">
          <span class="trip-plan-point__number">{{params.index}}</span>
          <router-link :to="params.link"
                       class="trip-plan-point__title">{{params.title}}
          </router-link>
          <Rating class="trip-plan-point__rating"
                  :params="{id: params.id,
                  type: 'places',
                  rating: params.rating,
                  currentUserRating: params.currentUserRating}"/>
        </Row>
        <div class="trip-plan-point__image-holder">
          <img v-if="!!params.images"
               class="trip-plan-point__image"
               :src="params.images[0]"
               alt/>
        </div>
        <div v-if="params.annotation"
             class="trip-plan-point__annotation">
          <p class="trip-plan-point__annotation-text">{{params.annotation}}</p>
        </div>
        <transition name="fade">
          <div class="trip-plan-point__full-info"
               v-if="show">
            <div v-for="item in params.additions"
                 :key="item.id"
                 class="trip-plan-point__full-info-block">
              <Column>
                <div class="trip-plan-point__full-info-title">{{item.key}}</div>
                <div class="trip-plan-point__full-info-value">{{item.value}}</div>
              </Column>
            </div>
          </div>
        </transition>
        <div v-if="params.additions.length > 0">
          <span class="trip-plan-point__toggle"
                v-on:click="toggle">Узнать больше
          </span>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TripPlanPoint',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      array1: [],
      array2: [],
    };
  },
  created() {
    this.params.additions.forEach((item, index) => {
      if (index % 2 === 0) {
        this.array1 = [...this.array1, item];
      } else {
        this.array2 = [...this.array2, item];
      }
    });
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    svgImg() {
      if (!this.$typesSVG.length) return '<div></div>';
      const div = document.createElement('div');
      div.innerHTML = this.$typesSVG.find(svg => svg.url === this.params.type.image).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.setAttribute('width', '32px');
      svg.setAttribute('height', '32px');
      div.getElementsByClassName('color-marker-symbol').forEach((el) => {
        const opt = this.params.type.icon_settings;
        const element = el;
        element.style.fill = opt.color ? opt.color : '#000000';
        element.style.opacity = opt.transparency || 1;
      });
      return div.innerHTML;
    },
  },
};
</script>
